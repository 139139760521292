

.inputContainer {
    border: 0px solid blue;
    border-radius: 6px;
    margin-bottom: 5px;
    margin-right: 20px;
    width: 100%;
    height: 52px;
    padding-bottom: 0px;
}

.textareaContainer {
  border: 0px solid blue;
  border-radius: 6px;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  width: 95%;
  height: 175px;
}

.labelDiv {
    border: 0px solid red;
    font-size: 12px;
    font-weight: 500;
    padding-left: 5px;
    color: grey;
}

.inputDiv, .textareaDiv {
    font-size: 16px;
    border: 1px solid grey;
    border-radius: 6px;
    padding: 5px;
}

.inputField {
  font-size: 14px;
  padding-left: 5px;
  border: 0px solid orange;
  width: 98%
}

.textareaField {
  font-size: 12px;
  padding-left: 5px;
  border: 0px solid orange;
  width: 99%;
}

textarea:focus, input:focus{
  outline: none;
}


.errorDiv {
    border: 0px solid blue; 
    font-size: 10px;
    padding-left: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: red;
}




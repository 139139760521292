

#outerForm {
    border: 2px solid grey;
    border-radius: 6px;
    margin: 10px;
    padding: 20px;
    width: 80%;
}

#header {
    margin: 0;
    display: flex;
    border: 0px solid red;
    padding-bottom: 20px;

}

#headLine {
    font-size: 36px;
    width: 50%;
    float: left; 
    padding-right: 30px;  
}

#actionButtons {
    display: flex;
    justify-content: space-evenly;
    border: 0px solid blue; 
    width: 40% 
}

.rowsBox {
  border: 2px solid grey; 
  width: 90%;
  border-radius: 6px;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  background: beige;

}

.fieldsRow {
  border: 0px solid green; 
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.fromWikiHead {
    border: 1px solid grey; 
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

.textBlockLeft {
    border: 1px solid grey; 
    float: left;
    text-align: left;
    padding: 10px;
    width: 50%
  }
  
  .textBlockRight {
    border: 1px solid grey; 
    float: right;
    text-align: left;
    width: 50%;
    padding: 10px;
  }
  




.timestamp {
    font-size: 12px;
    line-height: 20px;
    color: red;
    font-weight: normal;
    width: 50%;
    margin-left: 10px;
    border: 0px solid blue; 
}




/* 
    input. {
      margin: '0.5rem 0',
      padding: '5px'
    },

  fieldSmall: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#606A74',
      fontWeight: 'normal',
      maxWidth: '80%',
      margin: '0px',
      padding: '0px',
      textAlign: 'left',
      contentAlign: 'left'
    },
    fieldMedium: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#606A74',
      fontWeight: 'normal',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px',
      textAlign: 'left',
      contentAlign: 'left'
    },
  
    fieldBig: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#606A74',
        fontWeight: 'normal',
        maxWidth: '100%',
        margin: '0px',
      },
   */

  
